/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages
        echo.init({
          offset: 1000,
          throttle: 250,
          unload: false,
          callback: function (element, op) {
            // console.log(element, 'has been', op + 'ed');
            var myEvent = new CustomEvent("lazyLoadComplete");
            document.body.dispatchEvent(myEvent);
          }
        });
        // add this function for a fixed header
        /*$(window).scroll(function(){
          if ($(window).scrollTop() >= 5) {
            $('body').addClass('fixed-header');
          }
          else {
            $('body').removeClass('fixed-header');
          }
        });*/
        $('.slick').slick({
          infinite: true,
          slidesToShow: 1,
          slidesToScroll: 1
        });

      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
        $('.home-slideshow').slick({
          fade: true,
          slidesToShow: 1,
          slidesToScroll: 1,
          autoplay: true,
          autoplaySpeed: 5000
        });
        $('.testimonial-slideshow').slick({
          infinite: true,
          slidesToShow: 2,
          slidesToScroll: 2
        });
        $('.product-carousel').slick({
          infinite: true,
          slidesToShow: 5,
          slidesToScroll: 1
        });		
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    },
    // Carousels page
    'carousels': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    },
    // Accordions and Tabs page
    'accordions_tabs': {
      init: function() {
        // JavaScript to be fired on the about us page
        /**** START TAB CODE ****/
		$('.tabs > ul > li:first-child').addClass('active'); // Trigger first child as Active. This makes it so no additional hardcoded markup is necessary
        $.each($('.tabs'), function(i,v){
          var $target = $(this),
              $label_row = $target.children('ul'),
              label_row_height = $target.hasClass('horizontal') ? $label_row.height() : 0,
              t=0, // the height of the highest element (after the function runs)
              t_elem;  // the highest element (after the function runs)
          $("*",$target).each(function () {
              $this = $(this);
              if ( $this.outerHeight() > t ) {
                  t_elem=this;
                  t=$this.outerHeight();
              }
          });
          $target
            .css({
              'height': t + 20, // this is temporary to fix the stupid height bug
              'margin-bottom': label_row_height
            })                      // Container height to largest content with bottom margin to compensate for label row
            .find('.tabs > ul')             // Find all child containers
            .css('height', '100%'); // Set all child containers to height 100% (order required for height adjustment);
        });
        $('.tabs > ul > li').on('click', function(e){
          var $target = $(this),
              $siblings = $target.siblings('li');
          $siblings.removeClass('active');
          $target.addClass('active').trigger('tabChanged');
          // need to fix later
          $.each($('.tabs'), function(i,v){
            var $target = $(this),
                $label_row = $target.children('ul'),
                label_row_height = $target.hasClass('horizontal') ? $label_row.height() : 0,
                t=0, // the height of the highest element (after the function runs)
                t_elem;  // the highest element (after the function runs)
            $("*",$target).each(function () {
                $this = $(this);
                if ( $this.outerHeight() > t ) {
                    t_elem=this;
                    t=$this.outerHeight();
                }
            });
            $target
              .css({
                'height': t + 20, // this is temporary to fix the stupid height bug
                'margin-bottom': label_row_height
              })                      // Container height to largest content with bottom margin to compensate for label row
              .find('.tabs > ul')             // Find all child containers
              .css('height', '100%'); // Set all child containers to height 100% (order required for height adjustment);
          });
        });
        
        /**** END TAB CODE ****/

        /**** START ACCORDION CODE ****/

        $('.accordion-label').click(function() {
          var content = this.nextElementSibling;
          $('.accordion-label').not($(this)).removeClass('active').attr('aria-expanded', 'false');
          $('.accordion-content').css('max-height', '');
          if ($(this).is('.active')) {
            $(this).toggleClass('active').attr('aria-expanded', 'false');
            heightVal = '0';
          } else {
            $(this).toggleClass('active').attr('aria-expanded', 'true');
            heightVal = content.scrollHeight + "px";
          }
          $(content).css('max-height', heightVal);
        });



        /* var d = document,
        accordionLabels = d.getElementsByClassName("accordion-label"),
        i;

        for (i = 0; i < accordionLabels.length; i++) {
          accordionLabels[i].onclick = function() {

            this.classList.toggle("active");

            var content = this.nextElementSibling;
            if (this.getAttribute('aria-expanded') === 'true') {
              content.style.maxHeight = null;
              this.setAttribute('aria-expanded', 'false');
            } else {
              content.style.maxHeight = content.scrollHeight + "px";
              this.setAttribute('aria-expanded', 'true');
            }
          };
        }
        */
        /**** END ACCORDION CODE ****/

      }
    },


    // Panels page
    'panels': {
      init: function() {
        
      },
      finalize: function() {
        $('.testimonial-slideshow').slick({
          infinite: true,
          slidesToShow: 2,
          slidesToScroll: 2
        });
        // CTA BAR SCRIPT
        $(document).ready(function($) {

            // Check if mobile is true
            var isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry/i.test(navigator.userAgent) ? true : false;

            // Get positions to check if cta-bar is above the fold
            var wrapperPos = $('.sticky-cta-wrap').position().top;
            var windowHeight = $(window).height();

            // Log positions for troubleshooting
            //console.log('wrapperpos ' + wrapperPos);
            //console.log('windowheight ' + windowHeight);

            function onScroll() {

                // fade in and fade out if scrolled more than scrollDistance
                var scrollDistance = 0; // Change to whatever scroll distance you want in PX values

                var scrollTop = $(document).scrollTop();

                 //console.log(scrollDistance);
                 //console.log(scrollTop);

                function animateIn() {
                    $('.sticky-cta-bar').addClass('fadein');
                }
                function animateOut() {
                    $('.sticky-cta-bar').removeClass('fadein');
                }

                if (scrollTop > scrollDistance) {
                    animateIn();
                } else if (scrollTop < scrollDistance) {
                    animateOut();
                }

                // Get distance from top of site to bottom of cta wrapper
                var headerHeight = $('header').outerHeight();
                var ctaHome = ($('.sticky-cta-wrap').position().top + $('.sticky-cta-bar').outerHeight() + headerHeight);
                var ctaHeight = $('.sticky-cta-bar').outerHeight();
                
                //console.log('headerHeight ' + headerHeight);
                //console.log('ctaHeight ' + ctaHeight);
                //console.log('ctaHome ' + ctaHome);
                
                //console.log(ctaHome);
                // imhome class changes div to position: static; placing it back into the dom
                if (ctaHome >= ($(window).scrollTop()+$(window).height())) {
                    $('.sticky-cta-bar').removeClass('imhome');
                } else {
                    $('.sticky-cta-bar').addClass('imhome');
                }
            }

            if (isMobile == false) {
                if (wrapperPos >= windowHeight) { // if cta-bar is below the fold

                    

                    // execute the function
                    $(window).scroll(function() {
                        onScroll();
                    });

                } else if (wrapperPos <= windowHeight) {  // if the cta-bar is above the fold, put it in it's home

                    $('.sticky-cta-bar').addClass('imhome');

                } // end below the fold check
            } else if (isMobile == true) {

                    $('.sticky-cta-bar').addClass('imhome');
            } // end mobile check
        });
      },
    },
    // Gallery/Portfolio page
    'galleryportfolio': {
      init: function() {

        // Size grid parents by content
        function masonrySizer() {
          $.each($('.grid').find('img'), function(i,v) {
            var $ele = $(this),
                $parent = $ele.parent();

            $parent.height('auto'); // reset parent height or expanding upwards will bug out due to ele height restricting image height

            var $eleHeight = $ele.height(),
                $eleWidth = $ele.width();

            $parent.height($eleHeight); // Set parent height to image height
          });
        }

        function startMasonry() {
          $('.grid').masonry({
            itemSelector: '.grid-item',
            columnWidth: '.grid-sizer'
          });
        }

        $( window ).resize(function() {
          masonrySizer();
        });

        $(document).ready(function(){
          masonrySizer();
        });


        document.body.addEventListener("lazyLoadComplete", startMasonry, false);


      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },

  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
